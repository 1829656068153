exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/authors.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-authors-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-checklists-jsx": () => import("./../../../src/pages/checklists.jsx" /* webpackChunkName: "component---src-pages-checklists-jsx" */),
  "component---src-pages-cleaning-services-jsx": () => import("./../../../src/pages/cleaning-services.jsx" /* webpackChunkName: "component---src-pages-cleaning-services-jsx" */),
  "component---src-pages-clients-jsx": () => import("./../../../src/pages/clients.jsx" /* webpackChunkName: "component---src-pages-clients-jsx" */),
  "component---src-pages-construction-jsx": () => import("./../../../src/pages/construction.jsx" /* webpackChunkName: "component---src-pages-construction-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-elevatetech-services-jsx": () => import("./../../../src/pages/elevatetech-services.jsx" /* webpackChunkName: "component---src-pages-elevatetech-services-jsx" */),
  "component---src-pages-garpi-cement-jsx": () => import("./../../../src/pages/garpi-cement.jsx" /* webpackChunkName: "component---src-pages-garpi-cement-jsx" */),
  "component---src-pages-hospitality-jsx": () => import("./../../../src/pages/hospitality.jsx" /* webpackChunkName: "component---src-pages-hospitality-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-inspections-and-audit-jsx": () => import("./../../../src/pages/inspections-and-audit.jsx" /* webpackChunkName: "component---src-pages-inspections-and-audit-jsx" */),
  "component---src-pages-integrations-jsx": () => import("./../../../src/pages/integrations.jsx" /* webpackChunkName: "component---src-pages-integrations-jsx" */),
  "component---src-pages-issues-jsx": () => import("./../../../src/pages/issues.jsx" /* webpackChunkName: "component---src-pages-issues-jsx" */),
  "component---src-pages-knowledge-base-checklists-jsx": () => import("./../../../src/pages/knowledge-base-checklists.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-checklists-jsx" */),
  "component---src-pages-knowledge-base-external-api-jsx": () => import("./../../../src/pages/knowledge-base-external-api.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-external-api-jsx" */),
  "component---src-pages-knowledge-base-get-started-jsx": () => import("./../../../src/pages/knowledge-base-get-started.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-get-started-jsx" */),
  "component---src-pages-knowledge-base-issues-jsx": () => import("./../../../src/pages/knowledge-base-issues.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-issues-jsx" */),
  "component---src-pages-knowledge-base-jsx": () => import("./../../../src/pages/knowledge-base.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-jsx" */),
  "component---src-pages-knowledge-base-locations-jsx": () => import("./../../../src/pages/knowledge-base-locations.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-locations-jsx" */),
  "component---src-pages-knowledge-base-organization-creation-jsx": () => import("./../../../src/pages/knowledge-base-organization-creation.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-organization-creation-jsx" */),
  "component---src-pages-knowledge-base-payments-jsx": () => import("./../../../src/pages/knowledge-base-payments.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-payments-jsx" */),
  "component---src-pages-knowledge-base-points-jsx": () => import("./../../../src/pages/knowledge-base-points.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-points-jsx" */),
  "component---src-pages-knowledge-base-reports-jsx": () => import("./../../../src/pages/knowledge-base-reports.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-reports-jsx" */),
  "component---src-pages-knowledge-base-schedule-jsx": () => import("./../../../src/pages/knowledge-base-schedule.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-schedule-jsx" */),
  "component---src-pages-knowledge-base-task-creation-jsx": () => import("./../../../src/pages/knowledge-base-task-creation.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-task-creation-jsx" */),
  "component---src-pages-knowledge-base-task-execution-jsx": () => import("./../../../src/pages/knowledge-base-task-execution.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-task-execution-jsx" */),
  "component---src-pages-knowledge-base-telegram-jsx": () => import("./../../../src/pages/knowledge-base-telegram.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-telegram-jsx" */),
  "component---src-pages-knowledge-base-users-jsx": () => import("./../../../src/pages/knowledge-base-users.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-users-jsx" */),
  "component---src-pages-knowledge-base-webhooks-jsx": () => import("./../../../src/pages/knowledge-base-webhooks.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-webhooks-jsx" */),
  "component---src-pages-manufacturing-jsx": () => import("./../../../src/pages/manufacturing.jsx" /* webpackChunkName: "component---src-pages-manufacturing-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-prime-cleaning-jsx": () => import("./../../../src/pages/prime-cleaning.jsx" /* webpackChunkName: "component---src-pages-prime-cleaning-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-tour-jsx": () => import("./../../../src/pages/product-tour.jsx" /* webpackChunkName: "component---src-pages-product-tour-jsx" */),
  "component---src-pages-reporting-jsx": () => import("./../../../src/pages/reporting.jsx" /* webpackChunkName: "component---src-pages-reporting-jsx" */),
  "component---src-pages-retail-jsx": () => import("./../../../src/pages/retail.jsx" /* webpackChunkName: "component---src-pages-retail-jsx" */),
  "component---src-pages-savita-oil-technologies-jsx": () => import("./../../../src/pages/savita-oil-technologies.jsx" /* webpackChunkName: "component---src-pages-savita-oil-technologies-jsx" */),
  "component---src-pages-security-guard-services-jsx": () => import("./../../../src/pages/security-guard-services.jsx" /* webpackChunkName: "component---src-pages-security-guard-services-jsx" */),
  "component---src-pages-sobek-security-solutions-jsx": () => import("./../../../src/pages/sobek-security-solutions.jsx" /* webpackChunkName: "component---src-pages-sobek-security-solutions-jsx" */),
  "component---src-pages-task-management-jsx": () => import("./../../../src/pages/task-management.jsx" /* webpackChunkName: "component---src-pages-task-management-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-tours-and-walkarounds-jsx": () => import("./../../../src/pages/tours-and-walkarounds.jsx" /* webpackChunkName: "component---src-pages-tours-and-walkarounds-jsx" */)
}

